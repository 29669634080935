import './App.css';
import NoLoginApp from './nologin/App';
import { BrowserRouter } from 'react-router-dom';

function App() {
	return (
		<>
			<BrowserRouter basename="/">
				{
				 <NoLoginApp />
				}
			</BrowserRouter>
		</>
	);
}

export default App;
