import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <>
         <header id="header" class="header-v1">
            <div class="header-holder">
                <div class="logowrap">
                    <a href="index.html" class="logo">
                        <Link to='/'>
                        <img src="assets/images/Yenna-Logo.png" alt="Yenna Tech" class="header-v1-logo img-fluid" />
                        <img src="assets/images/Yenna-Logo-dark.png" alt="Yenna Tech" class="header-v1-fixed-logo img-fluid" />
                        </Link>
                    </a>
                </div>

                <div class="hamburger">
                    <div id="yt-menu-sbar1" class="ham-bar"></div>
                    <div id="yt-menu-sbar2" class="ham-bar"></div>
                    <div id="yt-menu-sbar3" class="ham-bar"></div>
                </div>

                <div class="navwrap">
                    <ul>   
                        <Link to='/Investors'><li><a href="token.html">Investors</a></li></Link>
                        <Link to='/join-us'><li><a href="#">Join Us</a></li></Link>
                        {/* <li><a href="#">Trading</a></li>
                        <li><a href="#">Resources</a></li>
                        <li><a href="#">Company</a></li> */}
                       <Link to='/signup'><li><a href="dash/017_Yenna_SignUp_v1.html" class="link-button">Signup</a></li></Link>
                    </ul>
                </div>

                <div class="mobilenavwrap">
                    <div class="mobilenav">
                        <ul>
                        <Link to='/Investors'><li><a href="token.html">Investors</a></li></Link>
                        <Link to='/join-us'><li><a href="#">Join Us</a></li></Link>
                            {/* <li><a href="#">Token Sale</a></li>
                            <li><a href="#">Digitize</a></li>
                            <li><a href="#">Trading</a></li>
                            <li><a href="#">Resources</a></li>
                            <li><a href="#">Company</a></li> */}
                             <Link to='/signup'><li><a href="dash/017_Yenna_SignUp_v1.html" class="link-button">Signup</a></li></Link>
                        </ul>
                    </div>
                </div>
            </div>

        </header>
    </>
  )
}

export default Navbar