import React from 'react'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faLinkedin, faInstagram, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <> 
        
        <footer>
            <div class="main-footer pt-3">
                <div class="footerholder">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt-5 pt-5 yt-logocol">
                            <div class="footer-logo">
                                <img src="assets/images/Yenna-Square-Logo.png" alt="Yenna Tech" class="img-fluid" />
                            </div>
                            <h3 class="company-desc">Digital experience is always embedded in a physical experience.
                            </h3>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt-5 pt-5 yt-linkscol">
                            <div class="yt-footerlinkswrap">
                                <h4>To Click</h4>
                                <ul>
                                <Link to='/'><li><a >Digitize</a></li></Link>
                                <Link to='/why-Invest'><li><a >Why Invest</a></li></Link>
                                <Link to='/'><li><a >Blogs</a></li></Link>
                                    <li><a class="PartnerwithusLink"  data-toggle="modal" data-target="#Partnerwithus">Partner with us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 mt-5 pt-5 yt-addresscol">
                            <h4 class="yt_addresswap_title">Address</h4>
                            <div class="yt-addresswrap">
                                <div class="yt-addressblock">
                                    <h5>United Kingdom</h5>
                                    <p>Kemp House, 160 City Road, 
                                        London, Ec1v 2nx </p>
                                </div>
                                <div class="yt-addressblock">
                                    <h5>India</h5>
                                    <p>8-2-203/82/A/73 2 Suit, 
                                        302 Sasi, Icon Road No. 6, 
                                        Jubilee Hills, Hyd-33, T.S</p>
                                    <p><a href="tel:4068271111"><i class="fas fa-phone-alt"></i> +91 40 6827 1111</a></p>
                                </div>
                                <div class="yt-addressblock">
                                    <h5>United States</h5>
                                    <p>2055 Limestone Rd Street 200c, 
                                        Wilmington DE 19808</p>
                                    <p><a href="tel:5596666468"><i class="fas fa-phone-alt"></i> +1 559 666 6468</a></p>
                                </div>
                            </div>
                            <div class="yt-addresswrap yt-mailaddress">
                                <div class="yt-addressblock"><p><a href="mailto:admin@yenna.tech"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> admin@yenna.tech</a></p></div>
                                <div class="yt-addressblock"><p><a href="tel:4068271111"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> +91 40 6827 1111</a></p></div>
                                <div class="yt-addressblock"><p><a href="tel:5596666468"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> +1 559 666 6468</a></p></div>
                            </div>
                            <div class="yt-subscribewrap">
                                <p>For quick connect</p>
                                <div class="col-lg-12">
                                <form method="post" action="contact-form-handler.php">
                                    <div class="yt-subscribeform">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-4 col-sm-4 yt-subscribeformlock"><input type="text" name="yt_name" required placeholder="Your Name" /></div>
                                            <div class="col-lg-5 col-md-4 col-sm-4 yt-subscribeformlock"><input type="email" name="yt_email" required placeholder="Your Email" /></div>
                                            <div class="col-lg-2 col-md-4 col-sm-4 yt-subscribeformlock"><button type="submit">Submit</button></div>
                                        </div>
                                    </div>
                                </form></div>
                            </div>
                        </div>
                    </div>
                    <div class="yt-socialmedia-linkwrap">
                        <ul>
                            <li><a href="https://www.linkedin.com/company/68531517/admin/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon></a></li>
                            <li><a href="https://twitter.com/YennaTech" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a></li>
                           
                            <li><a href="https://www.facebook.com/YennaTech" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon></a></li>
                            <li><a href="#" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon></a></li>
                            <li><a href="https://instagram.com/yenna.tech?utm_medium=copy_link" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="sub-footer bg-yellow">
                <div class="sub-footerholder inverse">
                    <div class="yt-policylinkswrap">
                        <a href="#">Terms & conditions</a> |
                        <a href="#">FAQ</a> |
                        <a href="#">Privacy & GDPR Policies</a> |
                        <a href="#">Accessibility</a> |
                        <a href="#">Legal Disclaimers</a>
                    </div>
                    <div class="yt-copyright">Designed to care © Yenna Tech 2021. All right reserved</div>
                </div>
            </div>
            <div class="modal fade Partnerwithus" id="Partnerwithus" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document"> 
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">YENNA TECH <span class="gold-color">Partner with us</span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="input-content">
                                <label>First Name*</label>
                                <input type="text" class="form-control" placeholder="Your First Name " value="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="input-content">
                                <label>Last Name*</label>
                                <input type="text" class="form-control" placeholder="Your Last Name " value="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <div class="input-content">
                                <label>Company*</label>
                                <input type="text" class="form-control" placeholder="Company" value="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="input-content">
                                <label>Email*</label>
                                <input type="email" class="form-control" placeholder="Email" value="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="input-content">
                                <label>Phone Number*</label>
                                <input type="text" class="form-control" placeholder="Phone Number" value="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn Partnerwithus-btn">SUBMIT</button>
            </div>
        </div>
        </div>
    </div>
        </footer>

    </>
  )
}

export default Footer