import React from 'react';
import ReactDOM from 'react-dom';
import BaseApp from './BaseApp';

ReactDOM.render(
<BaseApp />,
  document.getElementById('root')
);
  // {/* <React.StrictMode>
  //   <BaseApp />
  // </React.StrictMode> */} 