import { useLottie } from "lottie-react";
//import homepageanim from "../../../nologin/json/banner_json_v03.json";

const HomeAnime = ({anim}) => {
  const options = {
    animationData: anim,
    loop: true,
    autoplay: true,
    renderer: 'svg'
  };

  const { View } = useLottie(options);

  return View;
};

export default HomeAnime;