import React from "react";
import { Route, Switch } from "react-router";
import Digitization from "../pages/Digitization";
//import Index1 from "../pages/Index1";
//const Digitization = React.lazy(() => import("../pages/Digitization"));
const Invest = React.lazy(() => import("../pages/Invest"));
const Login = React.lazy(() => import("../pages/Login"));
const Signup = React.lazy(() => import("../pages/Signup"));
const Index1 = React.lazy(() => import("../pages/Index1"));

const Token = React.lazy(() => import("../pages/Token"));

export default function Layout() {
    return (
        <>
            <Switch>
                {/*
                    routes.map((v, i) => (
                            <Route key={i} path={v.url} exact={true}>
                                <React.Suspense fallback={<>Loading YennaTech...</>}>
                                 <v.component />
                                </React.Suspense>
                            </Route>
                    ))
                    */}
                
              <Route path="/" exact={true}>
                    <Digitization/>
              </Route>
              <Route path="/join-us" exact={true}>
              <React.Suspense fallback={<>Loading YennaTech...</>}>
                    <Index1/>
                    </React.Suspense>

              </Route>
              
              <Route  path='/signup' exact={true}>
                    <React.Suspense fallback={<>Loading YennaTech...</>}>
                        <Signup />
                    </React.Suspense>
                </Route>
                <Route  path='/login' exact={true}>
                    <React.Suspense fallback={<>Loading YennaTech...</>}>
                        <Login />
                    </React.Suspense>
                </Route>
                <Route  path='/token' exact={true}>
                    <React.Suspense fallback={<>Loading YennaTech...</>}>
                        <Token />
                    </React.Suspense>
                </Route>
                <Route  path='/Investors' exact={true}>
                    <React.Suspense fallback={<>Loading YennaTech...</>}>
                        <Invest />
                    </React.Suspense>
                </Route>
            </Switch>
        </>
    );
}