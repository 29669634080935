import Layout from './views/layout/Layout';

function App() {
	return (
		<>
			<Layout />
		</>
	);
}

export default App;
