import React from 'react'
import Footer from './Footer'
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faFacebook, faLinkedin, faInstagram, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons'
//import { faEnvelope, faMessage, faMinus, faPhone, faPlus } from '@fortawesome/free-solid-svg-icons'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import farming from '../../../nologin/json/Farming.json'
import digi1 from '../../../nologin/json/icon01.json'
import digi2 from '../../../nologin/json/icon02.json'
import digi3 from '../../../nologin/json/icon03.json'
import digi4 from '../../../nologin/json/icon04.json'
import digi5 from '../../../nologin/json/icon05.json'
import HomeAnime from './HomeAnime'


function Digitization() {
  return (
    <>
        <div id="wrapper">
       
       <Navbar />
        <section class="yt-section yt-intro">
            <div class="yt-sectionwrap">
                <div class="yt-sectionholder">
                    <div class="section-textwrap digitization-section-1-left">
                        <h1>Farming<br />
                            is <span class="col-primary">Sacred</span>
                        </h1>
                        <p>Thus starting with promoting farm lands, We propose a new financing way for anyone owning land in the world. We allow land owners 
of the world to borrow against their land by fractionalizing them and borrowing against it.</p>
                        <div class="section-text-btn">
                           <Link to="/" class="bg-yellow"><span>List our Asset</span></Link> 
                        </div>
                    </div>
                    <div class="intro-bannerwrap">
                        <div class="intro-bannerani" id="homeintrobanner_ani">
                        <HomeAnime anim={farming}/>  
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="yt-section yt-calc bg-yellow">
            <div class="yt-sectionwrap">
                <div class="digitization-section-2">
                    <div class="digitization-section-2-1">
                        <h6>YENNA’S Digital land offering</h6>
                        <h1>5 Steps to "Tokenization"</h1>
                        <p>A smart contract governed automated process that covers regulatory needs as a service,
We have created the following  roles to assist farmers and other physical value-chain partners serving 
the farm industry</p>
                    </div>
                    <div class="digitization-section-2-2">
                        <div class="section-2-2-box">
                            <div class="digitization_icon1_ani_1">
                            <HomeAnime anim={digi1}/>
                            </div>
                            <h2>Verification</h2>
                            <p>post sign up - Clients complete KYC and their expectations for next steps are defined</p>
                        </div>
                        <div class="section-2-2-box">
                        <div class="digitization_icon1_ani_1">
                            <HomeAnime anim={digi2}/>
                            </div>
                            <h2>Evaluation</h2>
                            <p>Analysing varied set of documentation with authorized professional to validate the land and the overlying businesses  as seperate units. </p>
                        </div>
                        <div class="section-2-2-box">
                        <div class="digitization_icon1_ani_1">
                            <HomeAnime anim={digi3}/>
                            </div>
                            <h2>Securitization</h2>
                            <p>Proposing a new fractinal commodities by clubbing the value of every unit of land with the fractional value of the overlying asset</p>
                        </div>
                    </div>
                    <div class="digitization-section-2-2">
                        <div class="section-2-2-box">
                        <div class="digitization_icon1_ani_1">
                            <HomeAnime anim={digi4}/>
                            </div>
                            <h2>Custodian</h2>
                            <p>Securing the original land document in a vault, brings legitimate value to the tokens reprenting the fractional commodities </p>
                        </div>
                        <div class="section-2-2-box">
                        <div class="digitization_icon1_ani_1">
                            <HomeAnime anim={digi5}/>
                            </div>
                            <h2>Transaction Assistant</h2>
                            <p>A dedicated account manager serves as a support guide  for clients and fractional investors with on-going investor queries</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="yt-section yt-economy digitization-section-3-top">
            <div class="yt-sectionwrap">
                <div class="digitization-section-3">
                    <div class="digitization-section-3-1">
                        <h1>WHY DIGITIZE<br/> <span class="col-primary">REAL WORLD ASSETS?</span></h1>
                        <p>It enables way of micro-financing, which allows any land owner around the globe raise funds from multiple fractional investors and share rewards at the end of the harvests while maintain transparency</p>
                    </div>
                    <div class="section-3-2-box-same section-3-2-box-1">
                        <h1>1</h1>
                        <div>
                            <h2>Simple & Enhanced Liquidity</h2>
                            <p>Demystifying blockchain with simple tools to leverage faster flow of funds into real-world assets</p>
                        </div>
                    </div>
                    <div class="section-3-2-box-same section-3-2-box-2">
                        <h1>2</h1>
                        <div>
                            <h2>Stay-in-control</h2>
                            <p>Ensuring client's have assured returns for fractional investors without having to share productivity and ownership rights</p>
                        </div>
                    </div>
                    <div class="section-3-2-box-same section-3-2-box-3">
                        <h1>3</h1>
                        <div>
                            <h2>Match-making with buyers</h2>
                            <p>Ease of finding reputed buyers allows client to promise assured returns</p>
                        </div>
                    </div>
                    <div class="section-3-2-box-same section-3-2-box-4">
                        <h1>4</h1>
                        <div>
                            <h2>Community & Crowd Support</h2>
                            <p>A decicated opportunity/recruitment tool with allows land owners to hire volunteers, interns and advisor with dedicated rewards</p>
                        </div>
                    </div>
                    <div class="section-3-2-box-same section-3-2-box-5">
                        <h1>5</h1>
                        <div>
                            <h2>Dashboards & Interfaces</h2>
                            <p>creates a seamless experience of understanding investments,expenditure, sale of produce/asset & returns to fractiona investors</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

    </div>
    </>
  )
}

export default Digitization